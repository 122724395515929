import { createRouter, createWebHistory } from 'vue-router'

const routes = [
    {
        path: '/',
        name: "App"
    },
    {
        path: '/:tipo/:id',
        name: 'Noticia',
        component: () => import('./views/Noticia.vue')
    }
]

const router = createRouter({
    history: createWebHistory(),
    routes
})

export default router